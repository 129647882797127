<script setup lang="ts">
import type { Modules, Reply } from '@/types/api.js'

const route = useRoute()
const nuxtApp = useNuxtApp()
const globalStore = useGlobal()
const isShowTaskMenu = ref(false)

const menus = [
  {
    name: 'Dashboard',
    key: 'dashboard',
    icon: 'hugeicons:dashboard-circle',
    route: `/modules/${route.params.id}`,
  },
  {
    name: 'Editar',
    key: 'edit',
    icon: 'hugeicons:edit-02',
    route: `/modules/${route.params.id}/edit`,
  },
  {
    name: 'Categorías',
    key: 'edit',
    icon: 'hugeicons:align-vertical-center',
    route: `/modules/${route.params.id}/categories`,
  },
  {
    name: 'Avisos',
    key: 'banners',
    icon: 'hugeicons:advertisement',
    route: `/modules/${route.params.id}/banners`,
  },
  {
    name: 'Versión',
    key: 'version',
    icon: 'hugeicons:auto-conversations',
    route: `/modules/${route.params.id}/version`,
  },
  {
    name: 'API',
    key: 'api',
    icon: 'hugeicons:code',
    route: `/modules/${route.params.id}/api`,
  },
]

async function getModuleById() {
  try {
    const response = await nuxtApp.$urlEfici.post<Reply<Modules>>(`${nuxtApp.$endpoint.modules.getId.url}${route.params.id}`)

    if (response.data.error)
      throw new Error('Error render modules in the client', { cause: `${response.status} - ${response.data.message}` })

    globalStore.module = {
      id: response.data.data.id!,
      name: response.data.data.nameModule!,
    }
  }
  catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    }
    return error
  }
}

onMounted(() => {
  if (Number(route.params.id) !== globalStore.module.id) {
    getModuleById()
  }
})
</script>

<template>
  <NuxtLayout>
    <div class="relative flex h-full gap-5 sm:h-[calc(100vh_-_150px)]">
      <div
        class="panel absolute z-40 hidden h-full w-[240px] max-w-full flex-none space-y-4 p-4 ltr:rounded-r-none rtl:rounded-l-none xl:relative xl:block xl:h-auto ltr:xl:rounded-r-md rtl:xl:rounded-l-md"
        :class="{ '!block': isShowTaskMenu }"
      >
        <div class="flex h-full flex-col pb-16">
          <div class="pb-5">
            <div class="flex items-center text-center">
              <div class="shrink-0">
                <Icon name="hugeicons:circle-arrow-up-double" />
              </div>
              <h3 class="text-lg font-semibold ltr:ml-3 rtl:mr-3">
                {{ globalStore.module.name }}
              </h3>
            </div>
          </div>
          <div class="mb-5 h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]" />

          <div class="space-y-1 overflow-y-auto scrollbar">
            <NuxtLink
              v-for="(menuItem, _index) in menus"
              :key="_index"
              :to="menuItem.route"
              class="flex h-10 w-full items-center gap-5 rounded-md p-2 font-medium hover:bg-white-dark/10 hover:text-primary dark:hover:bg-[#181F32] dark:hover:text-primary"
              :class="{ 'bg-gray-100 text-primary dark:bg-[#181F32] dark:text-primary': route.fullPath === menuItem.route }"
            >
              <Icon :name="menuItem.icon" />
              {{ menuItem.name }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        class="overlay absolute z-30 hidden h-full w-full rounded-md bg-black/50"
        :class="{ '!block xl:!hidden': isShowTaskMenu }"
        @click="isShowTaskMenu = !isShowTaskMenu"
      />
      <div class="panel h-full flex-1 overflow-auto p-4">
        <div class="flex items-center ltr:mr-3 rtl:ml-3 pb-4">
          <button
            type="button"
            class="block hover:text-primary ltr:mr-3 rtl:ml-3 xl:hidden"
            @click="isShowTaskMenu = !isShowTaskMenu"
          >
            <Icon name="hugeicons:menu-02" />
          </button>
        </div>
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>
